(function(window, document) {
	var DB = {};

	window.DB = DB;
	DB.Util = {
		DB_NAME: 'abagInteraktiv',
		DB_VERSION: 30,
		DB_STORE_NAME: 'OSM',
		DB_STORE_NAME_ADDS: 'ADDS',

		hasIndexedDB: true,
		projectdata: null,
		progress: 0,
		progressStep: 1,
		db: null,

		openDB: function() {
			var parent = this; //this.Util;
			var handleError = function(eMessage) {
				if (window.indexedDB) indexedDB.deleteDatabase(parent.DB_NAME);

				parent.hasIndexedDB = false;
			};

			return new Promise(function(resolve, reject) {
				//wenn db schon existent, dann ist hier schluss
				if (parent.db) resolve();

				//schauen ob IndexedDB überhaupt möglich
				if (!window.indexedDB) {
					handleError();
					reject();
					return;
				}
				try {
					var req = indexedDB.open(parent.DB_NAME, parent.DB_VERSION);
					req.onsuccess = function(evt) {
						parent.db = evt.target.result;

						//falls noch keine Stores da, dann etwas warten
						if (parent.db.objectStoreNames.length < 1) {
							Snackbar.show('Datenbank noch nicht verfügbar, warten...');
							setTimeout(function() {
								parent.blobStoreTest(false).then(
									function(value) {
										resolve();
									},
									function() {
										reject();
									}
								);
							}, 10000);
						} else {
							// Testen, ob Blob-sichern funktioniert.
							parent.blobStoreTest(false).then(
								function(value) {
									resolve();
								},
								function() {
									reject();
								}
							);
						}
					};

					req.onerror = function(evt) {
						console.error('openDb:', evt.target.error);
						handleError(evt.target.error.message);
						reject();
					};

					req.onupgradeneeded = function(evt) {
						parent.db = evt.target.result;

						// Anlegen der Stores mit Index (Tabellen)
						//parent.db.createObjectStore( parent.DB_STORE_NAME, { keyPath: 'id'});
						parent.db.createObjectStore(parent.DB_STORE_NAME);
						parent.db.createObjectStore(parent.DB_STORE_NAME_ADDS);
					};
				} catch (e) {
					// z.B. Chrome auf IPad hat kein IndexedDB
					// z.B. Opera auf PC

					handleError(e.message + ' openDB');
					reject();
					return;
				}
			});
		},

		getObjectStore: function(store_name, mode) {
			var tx = this.db.transaction(store_name, mode);
			// Falls was schief geht
			tx.onerror = function(ev) {
				console.log('Fehler in Transaction: ' + store_name + ' (' + tx.error + ' )');
				console.log(ev);
			};

			return tx.objectStore(store_name);
		},

		isDownloaded: function() {
			var parent = this;
			return new Promise(function(resolve, reject) {
				var check = function() {
					var store = parent.getObjectStore(parent.DB_STORE_NAME, 'readwrite');
					const req = store.openCursor();
					req.onsuccess = function(evt) {
						const cursor = evt.target.result;
						if (cursor) {
							return resolve(true);
						} else {
							return resolve(false);
						}
					};
				};

				if (!this.db) {
					DB.Util.openDB().then(function() {
						check();
					});
				} else {
					check();
				}
			});
		},

		_handleRequestData: function(url) {
			var parent = this;
			return new Promise(function(resolve, reject) {
				try {
					var xhr = new XMLHttpRequest();
					xhr.open('GET', url, true);
					xhr.responseType = 'blob'; // You can't do this with jQuery ajax, but with native XMLHttpRequest
					xhr.onload = function(evt) {
            //wenn Fehler dann weiter mit null
            if (xhr.status == 404) return resolve(null);
            
            //Wert gefunden
						if (xhr.status == 200 || xhr.status == 206) {
							//206 manchmal bei Firefox, PC
							if (parent.getBlobSaving() == 1) {
								return resolve(xhr.response);
							}
							if (parent.getBlobSaving() == 2) {
								parent.base64encode(xhr.response).then(function(value) {
									return resolve(value);
								});
							}
						}
					};
					xhr.onerror = function(evt) {
						return reject();
					};
					xhr.onabort = function(evt) {
						return reject();
					};

					xhr.send();
				} catch (e) {
					return resolve(null);
				}
			});
		},

		storeAdds: function(addArray, callback, sum) {
			var parent = this;
			var storeAdd = function(key, value) {
				try {
					var store = parent.getObjectStore(parent.DB_STORE_NAME_ADDS, 'readwrite');

					req = store.add(value, key); // nicht put???
					req.onsuccess = function(evt) {
						parent.storeAdds(addArray, callback, sum);
					};
					req.onerror = function() {
						parent.storeAdds(addArray, callback, sum);
					};
				} catch (e) {
					console.log('Kann nicht speichern ' + e.name);
					parent.storeAdds(addArray, callback, sum);
				}
			};

			if (addArray.length > 0) {
				var path = addArray.shift();

				parent._handleRequestData(path).then(
					function(result) {
						if (result) {
							if (!sum) sum = [];
							sum.push(result);
							storeAdd(path, result);
						} else {
              parent.storeAdds(addArray, callback, sum);
            }
					},
					function() {
						parent.storeAdds(addArray, callback, sum);
					}
				);
			} else {
				//alles abgearbeitet
				if (callback) callback(sum);
			}
		},

		getAdd: function(key) {
			var parent = this;
			return new Promise(function(resolve, reject) {
				var store = parent.getObjectStore(parent.DB_STORE_NAME_ADDS, 'readwrite');
				var req = store.get(key);
				req.onsuccess = function(evt) {
					var record = evt.target.result;
					resolve(record);
				};
				req.onerror = function(evt) {
					reject('get error', this.error);
				};
			});
		},

		storeOSMTiles: function(tilesArray, callback) {
			var parent = this;

			//var storeOSMTile = function(key, value, tilesArray, callback) {
			var storeOSMTile = function(key, value) {
				//var parent = this;
				try {
					var store = parent.getObjectStore(parent.DB_STORE_NAME, 'readwrite');
					req = store.add(value, key);
					req.onsuccess = function(evt) {
						parent.storeOSMTiles(tilesArray, callback);
					};
					req.onerror = function() {
						parent.storeOSMTiles(tilesArray, callback);
					};
				} catch (e) {
					console.log('Kann nicht speichern ' + e.name);
					parent.storeOSMTiles(tilesArray, callback);
				}
			};

			if (tilesArray.length > 0) {
				var path = tilesArray.shift();

				parent._handleRequestData(path.image).then(
					function(result) {
						if (result) {
							console.log(tilesArray.length);
							storeOSMTile(path.key, result);
						} else {
              parent.storeOSMTiles(tilesArray, callback);
            }
					},
					function() {
						parent.storeOSMTiles(tilesArray, callback);
					}
				);
			} else {
				//alles abgearbeitet
				if (callback) callback();
			}
		},

		getOSMTile: function(key) {
			var parent = this;
			return new Promise(function(resolve, reject) {
				var store = parent.getObjectStore(parent.DB_STORE_NAME, 'readwrite');
				var req = store.get(key);
				req.onsuccess = function(evt) {
					var record = evt.target.result;
					resolve(record);
				};
				req.onerror = function(evt) {
					reject('get error', this.error);
				};
			});
		},

		blobStoreTest: function(second) {
			var parent = this;
			return new Promise(function(resolve, reject) {
				if (!isOnline) {
					resolve();
				}

				//falls keine Datenbank, dann nur online
				if (this.hasIndexedDB == false) {
					core.Util.analyseSystem();
					return;
				}

				var xhr = new XMLHttpRequest();
				xhr.open('GET', './syslib/images/logo.png', true);
				xhr.responseType = 'blob';
				xhr.onload = function(evt) {
					if (xhr.status == 200) {
						var store = parent.getObjectStore(parent.DB_STORE_NAME, 'readwrite');
						if (store) {
							try {
								req = store.add(xhr.response, -1);

								req.onsuccess = function(evt) {
									parent.setBlobSaving(1);
									parent.storeDelete(parent.DB_STORE_NAME);
									resolve();
								};
								req.onerror = function() {
									parent.setBlobSaving(2);
									parent.storeDelete(parent.DB_STORE_NAME);
									resolve();
								};
							} catch (e) {
								if (e.name == 'DataCloneError') {
									// Versuch als base64 zu speichern
									parent.base64encode(xhr.response).then(function(value) {
										// sichern
										var store = parent.getObjectStore(parent.DB_STORE_NAME, 'readwrite');
										try {
											req = store.add(value, -1);
											req.onsuccess = function(evt) {
												parent.setBlobSaving(2);
												parent.storeDelete(parent.DB_STORE_NAME);
												resolve();
											};
											req.onerror = function() {
												parent.storeDelete(parent.DB_STORE_NAME);
												this.hasIndexedDB = false;
												reject();
											};
										} catch (e) {
											// #rl auch hier hasIndexedDB = false?
											parent.hasIndexedDB = false;
											reject();
										}
									});
								} else {
									parent.hasIndexedDB = false;
									parent.storeDelete(parent.DB_STORE_NAME);
									reject();
								}
							}
						} else {
							parent.hasIndexedDB = false;
							reject();
						}
					}
				};
				xhr.onerror = function(evt) {
					if (second == true) {
						parent.hasIndexedDB = false;
						reject();
					} else parent.blobStoreTest(true);
				};
				xhr.onabort = function(evt) {
					if (second == true) {
						parent.hasIndexedDB = false;
						reject();
					} else parent.blobStoreTest(true);
				};
				xhr.send();
			});
		},

		setBlobSaving: function(value) {
			sessionStorage.setItem('BlobSaving', value);
		},

		getBlobSaving: function() {
			//Opera hat teilweise Probleme mit Blobs. Deshalb für Opera generell BASE64
			if (L.Browser.mobileOpera) {
				// Opera
				return 2;
			}
			// Werte 1, Hier kann blob gespeichert werden, 2: BASE 64 (z.b. IOS)
			// Hack für IOS 10, sollte wieder geändert werden
			if (L.Browser.safari) {
				return 2;
			} else {
				var blobSaving = sessionStorage.getItem('BlobSaving');
				return blobSaving == null ? 2 : blobSaving;
			}
		},

		clear: function() {
			this.storeDelete(this.DB_STORE_NAME, true);
			this.storeDelete(this.DB_STORE_NAME_ADDS, true);
		},

		storeDelete: function(storeName, all) {
			var store = this.getObjectStore(storeName, 'readwrite');
			if (all) store.clear();
			else store.delete(-1);
		},

		base64encode: function(binary) {
			return new Promise(function(resolve, reject) {
				var reader = new window.FileReader();
				reader.readAsDataURL(binary);
				reader.onloadend = function() {
					resolve(encodeURIComponent(reader.result));
				};
				reader.onerror = function(event) {
					reject(event.target.error.code);
				};
			});
		},

		base64ToArrayBuffer: function(base64) {
			var binary_string = atob(base64);
			var len = binary_string.length;
			var bytes = new Uint8Array(len);
			for (var i = 0; i < len; i++) {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes.buffer;
		},

		unzip: function(rawFile) {
			var byteArray = new Uint8Array(rawFile);
			var gunzip = new Zlib.Gunzip(byteArray);
			var dataArray = gunzip.decompress();
			return new TextDecoder('utf-8').decode(dataArray);
		}
	};

	// shortcuts for most used utility functions
	DB.openDB = DB.Util.openDB;
})(window, document);
