function initMapSel() {
	if (!navigator.onLine) return;

	document.body.insertAdjacentHTML('beforeend', '<div id="oSel" style="height=100vh;">' + '<div id="mapSel" class="mapdisplay"></div>' + '</div>');

	//sichtbar machen
	var ele = document.getElementById('oSel');

	//scrollbalken wegschalten
	document.body.style.overflow = 'hidden';
	var mapSel = L.map('mapSel');

	//auf gesamt Bayern zoomen
	var bounds = [
		[47.271, 8.97696],
		[50.5654, 13.8413]
	];

	if (BAkey == '') {
		/*
        osm = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            id: 'Openstreetmap',
        }).addTo(mapSel);
        */
		osm = L.tileLayer('//{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
			attribution: 'Kartendaten: &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>-Mitwirkende, <a href="http://viewfinderpanoramas.org">SRTM</a> | Kartendarstellung: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
		}).addTo(mapSel);
	} else {
		osm = L.tileLayer('https://wmtsod{s}.bayernwolke.de/wmts/by_amtl_karte/smerc/{z}/{x}/{y}', {
			//attribution: '&copy; Datenquellen: Bayerische Vermessungsverwaltung, Bundesamt für Kartographie und Geodäsie WebAtlasDE</a> contributors',
			attribution: '&copy; Datenquellen: Bayerische Vermessungsverwaltung, GeoBasis-DE / BKG 2017 (Daten verändert) Topographische Karten Amtliche Karten Parzellarkarte Geobasisdaten DTK',
			id: 'Amtliche_Karte_Bayern',
			subdomains: [1, 2, 3, 4, 5, 6, 7, 8, 9],
			transparent: true
		}).addTo(mapSel);
	}

	readAsyncFile('daten/daten_bayern/grenze_geojson.json', 'json', function(jsonText) {
		var data = JSON.parse(jsonText);
		var bayern = L.geoJson(data, { interactive: false }).addTo(mapSel);
		bounds = bayern.getBounds();

		mapSel.fitBounds(bayern.getBounds());
		//L.rectangle(bayern.getBounds(), {interactive:false, color: "#ff7800", weight: 1}).addTo(mapSel);
	});

	//Benutzer informieren, was zu tun ist
	Snackbar.show('Navigieren Sie in der Karte und klicken Sie in die Mitte des Bereiches, den Sie offline nutzen wollen.', 'Weiter');

	//Koordinaten abgreifen
	ele = document.getElementById('mapSel');
	ele.style.cursor = 'crosshair';
	mapSel.addEventListener('mousedown', function(ev) {
		ele.style.cursor = 'move';
	});
	mapSel.addEventListener('mouseup', function(ev) {
		ele.style.cursor = 'crosshair';
	});

	mapSel.addEventListener('click', function(ev) {
		var lat = ev.latlng.lat;
		var lng = ev.latlng.lng;

		/*
        // http://nominatim.openstreetmap.org/reverse?format=json&accept-language=de&lat=48&lon=9.6&zoom=18&addressdetails=1 
        // &json_callback=nominatim_callback
        var url = "https://nominatim.openstreetmap.org/reverse?format=json&email=info@uismedia.de&accept-language=de&zoom=18&addressdetails=1&lat=" + lat + "&lon=" + lng;
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", url, true);
        rawFile.onreadystatechange = function() {
            if (rawFile.readyState === 4 && rawFile.status == "200") {
                nominatim_callback(JSON.parse(rawFile.responseText));
            }
        };
        rawFile.send(null);
        */

		//Projektion für Daten festlegen
		proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
		var proj = proj4('EPSG:31464', [lng, lat]);

		var left = Math.floor(proj[0] / 1000);
		var right = Math.floor(proj[1] / 1000);

		//eleTiles zurücksetzen für neue Auswahl
		_eleTiles = [];

		var proms = [];
		proms.push(_checkEleTile(_eleTiles, left + '_' + right));

		var tLeftMin, tLeftMax, tRightMin, tRightMax;
		var buffer = 700;
		if (Math.floor((proj[0] - buffer) / 1000) < left) {
			tLeftMin = Math.floor((proj[0] - buffer) / 1000);
			proms.push(_checkEleTile(_eleTiles, tLeftMin + '_' + right));
		}
		if (Math.floor((proj[0] + buffer) / 1000) > left) {
			tLeftMax = Math.floor((proj[0] + buffer) / 1000);
			proms.push(_checkEleTile(_eleTiles, tLeftMax + '_' + right));
		}
		if (Math.floor((proj[1] - buffer) / 1000) < right) {
			tRightMin = Math.floor((proj[1] - buffer) / 1000);
			proms.push(_checkEleTile(_eleTiles, left + '_' + tRightMin));
		}
		if (Math.floor((proj[1] + buffer) / 1000) > right) {
			tRightMax = Math.floor((proj[1] + buffer) / 1000);
			proms.push(_checkEleTile(_eleTiles, left + '_' + tRightMax));
		}
		if (tLeftMin && tRightMin) {
			proms.push(_checkEleTile(_eleTiles, tLeftMin + '_' + tRightMin));
		}
		if (tLeftMin && tRightMax) {
			proms.push(_checkEleTile(_eleTiles, tLeftMin + '_' + tRightMax));
		}
		if (tLeftMax && tRightMin) {
			proms.push(_checkEleTile(_eleTiles, tLeftMax + '_' + tRightMin));
		}
		if (tLeftMax && tRightMax) {
			proms.push(_checkEleTile(_eleTiles, tLeftMax + '_' + tRightMax));
		}

		Promise.all(proms).then(function() {
			console.log(_eleTiles);
			closeMapSel();
		});
	});
}

function removeOfflineData() {
	// Schliessen des Menues
	document.getElementById('nav--super-vertical-responsive').checked = false;

	try {
		DB.Util.clear();
		caches
			.delete('abag-cache-v2_add')
			.then(function(boolean) {
				//EleTiles löschen
				_eleTiles = [];

				//wieder auf Download umschalten
				toggleDownloadDelete(true);
				//Meldung
				Snackbar.show('Offline Daten wurden erfolgreich entfernt.');
			})
			.catch(function() {
				Snackbar.show('Offline Daten konnten nicht entfernt werden.', 'Weiter');
			});
	} catch (e) {
		Snackbar.show('Offline Daten konnten nicht entfernt werden.', 'Weiter');
	}
}
/*
function nominatim_callback(result) {
    console.log("nominatim_callback");
    _rLKRGem = result;
}
*/

function _checkEleTile(_eleTiles, tile) {
	//uerpruefen ob Hohenpunkte als Datei existiert
	return new Promise(function(resolve, reject) {
		var url = getEleTileUrl(tile, true);
		var client = new XMLHttpRequest();
		client.open('HEAD', url);
		client.onload = function() {
			if (this.status === 200) {
				_eleTiles.push(tile);
			}

			resolve();
		};

		client.send();
	});
}

function closeMapSel() {
	Snackbar.close();
	//overflow wieder zurücksetzen
	document.body.style.overflow = '';

	var el = document.getElementById('oSel');
	//el.style.visibility = (el.style.visibility == "visible") ? "hidden" : "visible";
	el.parentNode.removeChild(el);

	// Schliessen des Menues
	document.getElementById('nav--super-vertical-responsive').checked = false;

	//Bundesland und Höhenkachelnamen im localStorage speichern
	localStorage.setItem('tiles', _eleTiles.join());

	//OSM und Elevation Kacheln speichern
	if (L.Browser.mobile) {
		_DoCaching();
	}
}

function _DoCaching() {
	//OSM Kacheln in indexeddb speichern
	DB.Util.openDB().then(function(value) {
		Snackbar.show('Daten werden jetzt heruntergeladen. Sie werden informiert, wenn der Download abgeschlossen ist.', 'Weiter');

		//indexeddb Tabellen loeschen
		DB.Util.storeDelete(DB.Util.DB_STORE_NAME, true);
		DB.Util.storeDelete(DB.Util.DB_STORE_NAME_ADDS, true);

		//callback für Höhen
		var success = function() {
			navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
			if (navigator.vibrate) {
				navigator.vibrate(500);
			}
			Snackbar.show('ABAG Interaktiv kann jetzt Offline verwendet werden');
			//umschalten auf Daten löschen
			toggleDownloadDelete(false);
		};

		var storeMaps = function() {
			//Hoehen schon mal bereitstellen
			createEleLayer().then(function() {
				getEleTilesBound(null,'bayern').then(function(bounds) {
					let osmTileArray = [],
						slopeTileArray = [],
						lTileArray = [];
					let s;
					if (BAkey == '') {
						//OpenStreetmap Kacheln sichern
						s = new StorageTileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
					} else {
						//Kacheln Amtliche Karte sichern
						s = new StorageTileLayer('https://wmtsod{s}.bayernwolke.de/wmts/by_amtl_karte/smerc/{z}/{x}/{y}', {
							subdomains: [1, 2, 3, 4, 5, 6, 7, 8, 9]
						});
					}
					s._getTileArray(osmTileArray, L.latLngBounds(bounds[0], bounds[1]), 17);

					//slope Kacheln sichern
					s = new StorageTileLayer('./daten/slope/{z}/{x}/{y}.png');
					s._getTileArray(slopeTileArray, L.latLngBounds(bounds[0], bounds[1]), 16);

					//lFakt Kacheln sicher
					s = new StorageTileLayer('./daten/lfakt/{z}/{x}/{y}.png');
					s._getTileArray(lTileArray, L.latLngBounds(bounds[0], bounds[1]), 16);

					//arrays zusammenführen
					const tileArray = osmTileArray.concat(slopeTileArray, lTileArray);

					//.. und wegspeichern und weiter zu success
					DB.Util.storeOSMTiles(tileArray, success);
				});
			});
		};

		//array mit den URLS zu den Höhen erstellen
		var adds = [];
		_eleTiles.forEach(function(tile) {
			adds.push(getEleTileUrl(tile, true));
			adds.push(getContourTileUrl(tile, true));
		});

		//Höhenpunkte sichern
		if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
			//Sichern über serviceworker im Cache
			navigator.serviceWorker.controller.postMessage(adds);

			//jetzt OSM sichern
			storeMaps();
		} else {
			//Sichern in indexeddb
			DB.Util.storeAdds(adds, storeMaps);
		}
	});
}
