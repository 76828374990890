_elePoints = null;
_baseLayerRadio = false;
_kpolygon = null;
map = null;

BlobImageOverlay = L.ImageOverlay.extend({
	_initImage: function () {
		//var img = this._image = create$1('img',
		var img = (this._image = L.DomUtil.create('img', 'leaflet-image-layer ' + (this._zoomAnimated ? 'leaflet-zoom-animated' : '') + (this.options.className || '')));

		img.onselectstart = L.falseFn;
		img.onmousemove = L.falseFn;

		// @event load: Event
		// Fired when the ImageOverlay layer has loaded its image
		img.onload = L.bind(this.fire, this, 'load');
		img.onerror = L.bind(this._overlayOnError, this, 'error');

		if (this.options.crossOrigin) {
			img.crossOrigin = '';
		}

		if (this.options.zIndex) {
			this._updateZIndex();
		}

		var url = this._url;
		img.src = URL.createObjectURL(url);
		img.onload = function () {
			URL.revokeObjectURL(url);
		};
		img.alt = this.options.alt;
	},
});

StorageTileLayer = L.TileLayer.extend({
	_setUpTile: function (done, tile, value, blob) {
		if (blob) {
			if (DB.Util.getBlobSaving() == 1) {
				value = URL.createObjectURL(value);
				tile.onload = L.bind(
					function (done, tile) {
						URL.revokeObjectURL(value);
						this._tileOnLoad(done, tile);
					},
					this,
					done,
					tile
				);
				tile.onerror = L.bind(
					function (done, tile) {
						URL.revokeObjectURL(value);
						this._tileOnError(done, tile);
					},
					this,
					done,
					tile
				);
			}
			if (DB.Util.getBlobSaving() == 2) {
				//value = decodeURIComponent(value)
				tile.onload = L.bind(
					function (done, tile) {
						L.TileLayer.prototype._tileOnLoad.apply(this, arguments);
					},
					this,
					done,
					tile
				);
				tile.onerror = L.bind(
					function (done, tile) {
						L.TileLayer.prototype._tileOnError.apply(this, arguments);
					},
					this,
					done,
					tile
				);
			}
		} else {
			tile.onload = L.bind(this._tileOnLoad, this, done, tile);
			tile.onerror = L.bind(this._tileOnError, this, done, tile);
		}

		tile.src = value;
		if (this.options.storage || !navigator.onLine) {
			this._map.invalidateSize();
		}
	},

	createTile: function (coords, done) {
		var tile = document.createElement('img');

		if (this.options.crossOrigin) {
			tile.crossOrigin = '';
		}

		/*
        	Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
        	http://www.w3.org/TR/WCAG20-TECHS/H67
        */
		tile.alt = '';

		var getOffline = function () {
			var key = self.getTileUrl(coords, true);
			DB.Util.getOSMTile(key).then(function (value) {
				if (value) {
					if (DB.Util.getBlobSaving() == 2) {
						value = decodeURIComponent(value);
					}
					self._setUpTile(done, tile, value, true);
				} else {
					//self._setUpTile(done, tile, self.getTileUrl(coords));
				}
			});
		};
		var self = this;
		if (this.options.storage || !navigator.onLine) {
			getOffline();
		} else {
			try {
				self._setUpTile(done, tile, self.getTileUrl(coords));
			} catch (e) {
				getOffline();
			}
		}

		return tile;
	},

	getTileUrl: function (coords, blob) {
		var data = {
			r: L.Browser.retina ? '@2x' : '',
			s: blob === true ? 'z' : this._getSubdomain(coords),
			x: coords.x,
			y: coords.y,
			z: this._getZoomForUrl(),
		};

		/*
		//wenn Offline Openstreetmap verwenden
        if (this.options.storage || !navigator.onLine) 
			return L.Util.template('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', L.extend(data, this.options));
*/

		return L.Util.template(this._url, L.extend(data, this.options));
	},

	//ab hier eigene Funktionen
	_getTileArray: function (tilesArray, bounds, maxZoom) {
		// hier den Array aufbauen.
		var zoom = 13; //mapTmp.getZoom();

		//http://tile.openstreetmap.org/13/4312/2847.png
		//https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png

		//console.log("Initzoom: " + zoom);

		if (!maxZoom) maxZoom = 19;
		var zoomStart = 13; //zoom - 5; // Sonst ist ueberhaupt keine Karte da, wenn man offline ausserhalb der Anwendung ist
		var zoomEnd = maxZoom; //Math.min((zoom + 3),20);

		//#am nativezoom
		var zoomSave = this._tileZoom;
		for (var z = zoomStart; z < zoomEnd; z++) {
			//#am nativezoom
			this._tileZoom = z;

			var lngMinTile = this._long2tile(bounds.getNorthWest().lng, z);
			var latMinTile = this._lat2tile(bounds.getNorthWest().lat, z);
			var lngMaxTile = this._long2tile(bounds.getSouthEast().lng, z);
			var latMaxTile = this._lat2tile(bounds.getSouthEast().lat, z);

			for (var x = lngMinTile; x <= lngMaxTile; x++) {
				for (var y = latMinTile; y <= latMaxTile; y++) {
					var url = this.getTileUrl({ z: z, x: x, y: y }); // eigentliches Image welches geblobt werden soll
					var urlStore = z + '/' + x + '/' + y + '.' + url.split('.').pop(); // Pfadsegment um lokal nach Tiles zu suchen
					var url2 = this.getTileUrl({ z: z, x: x, y: y }, true); // key darauf subDomain:z
					tilesArray.push({ image: url, local: urlStore, key: url2 });
				}
			}
			//#am nativezoom
			this._tileZoom = zoomSave;
		}
	},

	_long2tile: function (lon, zoom) {
		return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
	},

	_lat2tile: function (lat, zoom) {
		return Math.floor(((1 - Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) / Math.PI) / 2) * Math.pow(2, zoom));
	},
});

//************************************************
// * Karte
//************************************************

function initMap(fullMap, faktor) {
	if (!DB.Util.db && L.Browser.mobile) {
		DB.Util.openDB().then(function () {
			initMap2(fullMap, faktor);
		});
	} else initMap2(fullMap, faktor);
}

function initMap2(fullMap, faktor) {
	//if(map._container) {
	if (map) {
		map.off();
		map.remove();
		map = null;
	}
	if (fullMap === null) fullMap = true;
	if (localStorage.getItem('bl') == 'Bayern') {
		initMapBayern(fullMap, faktor);
	} else {
		initMapNRW(fullMap, faktor);
	}
}

function initMapBayern(fullMap, faktor) {
	//Projektion für Daten festlegen
	proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4

	//Events online/offline
	var dop80, flurstueck, lc;
	window.addEventListener('online', function () {
		updateOnlineMaps(true, dop80, flurstueck, lc);
		isOnline = true;
	});
	window.addEventListener('offline', function () {
		updateOnlineMaps(false, dop80, flurstueck, lc);
		isOnline = false;
	});

	map = L.map(faktor + 'map', {
		twoFingerZoom: true,
		editable: true,
	});

	//Aktionen nach laden der Karte
	map.on('load', function () {
		//Infos anzeigen
		if (faktor == 'ls') {
			Snackbar.show('Zur Eingabe des Linienverlaufs bitte Tool aktivieren');
		} else {
			Snackbar.show('Zur Abgrenzung der Berechnungsfläche bitte Polygon-Tool aktivieren');
		}

		if (L.Browser.mobile && fullMap == true) {
			//Karte im Fullscreenmode zeigen wenn mobile
			displayMapFullScreen(true, faktor);
		}

		// if (_rPosition.length > 0) {
		// 	setTimeout(function(){
		// 		map.setView(_rPosition, 14);
		// 		//map.flyTo(_rPosition, 14);
		// 	}, 2000);
		// }

		map.invalidateSize(); // redraw
	});

	//Höhenlinien
	let lGroup = L.layerGroup();
	map.on('zoomend', function () {
		drawContour(lGroup, lc);
	});
	map.on('moveend', function () {
		drawContour(lGroup, lc);
	});

	//if (L.Browser.mobile) {
	var loc_icon =
		'<svg class="svg-inline--fa fa-location fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 168c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.94-40-40s17.94-40 40-40 40 17.94 40 40-17.94 40-40 40zm240-64h-49.66C435.49 145.19 366.81 76.51 280 65.66V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v49.66C145.19 76.51 76.51 145.19 65.66 232H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h49.66C76.51 366.81 145.19 435.49 232 446.34V496c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-49.66C366.81 435.49 435.49 366.8 446.34 280H496c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM256 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144z"/></svg>';

	L.easyButton(loc_icon, function () {
		map.locate({
			setView: true,
			watch: false,
			timeout: 50000,
			enableHighAccuracy: true,
		})
			.on('locationfound', function (e) {
				var marker = L.marker([e.latitude, e.longitude]).bindPopup('aktueller Standort)');
				map.addLayer(marker);
			})
			.on('locationerror', function (e) {
				Snackbar.show('Standort kann nicht ermittelt werden');
			});
	}).addTo(map);
	//}

	//Karte positionieren anhand Kachelinformation
	//map.setView(_rPosition, 13);
	//Gesamt boundary erzeugen
	getEleTilesBound(map, 'bayern').then(function (bounds) {
		map.fitBounds(bounds);

		if (_rPosition.length > 0) {
		 		map.setView(_rPosition, 13);
		}
	});

	var isOffline = !isOnline;
	var mnz = 18;
	if (isOffline) mnz = 16;

	var osm;
	if (BAkey == '') {
		/*
        osm = new StorageTileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            id: 'Openstreetmap',
            storage: isOffline,
            maxZoom: 18,
            minZoom: 6, //13,
            maxNativeZoom: mnz
        }).addTo(map);
        */
		osm = new StorageTileLayer('//{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
			attribution: 'Kartendaten: &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>-Mitwirkende, <a href="http://viewfinderpanoramas.org">SRTM</a> | Kartendarstellung: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
			storage: isOffline,
			maxZoom: 18,
			minZoom: 6, //13,
			maxNativeZoom: mnz,
		}).addTo(map);
	} else {
		osm = new StorageTileLayer('https://wmtsod{s}.bayernwolke.de/wmts/by_amtl_karte/smerc/{z}/{x}/{y}', {
			//attribution: '&copy; Datenquellen: Bayerische Vermessungsverwaltung, Bundesamt für Kartographie und Geodäsie WebAtlasDE</a> contributors',
			attribution: '&copy; Datenquellen: Bayerische Vermessungsverwaltung, GeoBasis-DE / BKG 2017 (Daten verändert) Topographische Karten Amtliche Karten Parzellarkarte Geobasisdaten DTK',
			id: 'Amtliche_Karte_Bayern',
			subdomains: [1, 2, 3, 4, 5, 6, 7, 8, 9],
			storage: isOffline,
			maxZoom: 18,
			minZoom: 6, //13,
			maxNativeZoom: mnz,
		}).addTo(map);
	}

	const lFakt = new StorageTileLayer('./daten/daten_bayern/lfakt/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	const lsFakt = new StorageTileLayer('./daten/daten_bayern/lsfakt/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	const slope = new StorageTileLayer('./daten/daten_bayern/slope/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	const kFakt = new StorageTileLayer('./daten/daten_bayern/kfakt/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	// folgendes nur wenn online
	if (isOnline) {
		// hier wird immer nur ein request gesendet (benötigt wms plugin)
		//Luftbilder
		if (BAkey == '') {
			//dop80 = L.WMS.overlay('//www.geodaten.bayern.de/ogc/ogc_dop80_oa.cgi?', {
			//	layers: 'by_dop80c,by_copyright',
			//	minZoom: 8,
			//	transparent: true,
			//	crs: L.CRS.EPSG4326,
			//});
			dop80 = L.WMS.overlay('https://geoservices.bayern.de/od/wms/dop/v1/dop40?', {
				layers: 'by_dop40c',
				minZoom: 8,
				transparent: true,
				crs: L.CRS.EPSG4326,
			});
		} else {
			dop80 = L.tileLayer('https://wmtsod{s}.bayernwolke.de/wmts/by_dop/smerc/{z}/{x}/{y}', {
				attribution: '&copy;  Datenquellen: Bayerische Vermessungsverwaltung, Planet Observer Orthophoto DOP Luftbilder Geobasisdaten Satellitenbilder',
				id: 'Luftbild_Bayern',
				minZoom: 8,
				subdomains: [1, 2, 3, 4, 5, 6, 7, 8, 9],
			});
		}

		//Flurstücke
		flurstueck = L.WMS.overlay('//geoservices.bayern.de/wms/v1/ogc_feka.cgi', {
			layers: 'feldstueck',
			format: 'image/png',
			transparent: true,
		}).addTo(map);

		// zeitversetzt die flurstücke in den Vordergrund bringen
		// wird angesprungen bei zoomen und pannen
		var onMapZoomAndPan = function (e) {
			if (e.type == 'zoomend') {
				if (map.getZoom() > 10 && _baseLayerRadio == false) {
					_baseLayerRadio = true;
					console.log('mehr als 10');
					//Luftbilder anzeigen
					//dop80.addTo(map);
					//map.removeLayer(osm);
				} else if (map.getZoom() <= 10) {
					_baseLayerRadio = false;
					//OSM anzeigen
					//osm.addTo(map);
					//map.removeLayer(dop80);
				}
			}

			setTimeout(function () {
				flurstueck.bringToFront();
			}, 2500);
		};
		map.on('zoomend', onMapZoomAndPan);
		map.on('moveend', onMapZoomAndPan);
	}

	const attribution = document.querySelector('.leaflet-control-attribution');
	attribution.addEventListener('click', function (ev) {
		attribution.classList.toggle('attributionLong');
	});
	// Massstabsleiste
	L.control.scale({ imperial: false, position: 'bottomright' }).addTo(map);

	var baseLayer = {};
	if (isOnline) {
		/* Layer control anzeigen */
		//            "Openstreetmap": osm,
		var baseLayers = {
			'Amtliche Karte Bayern': osm,
			Luftbilder: dop80,
		};
	}

	//zuschaltbare Layer (nur bei ls)
	var overlayMaps = {};
	if (faktor == 'ls') {
		overlayMaps = {
			Höhenlinien: lGroup,
			Hanglänge: lFakt,
			Hangneigung: slope,
			Topographiefaktor: lsFakt,
		};
	} else {
		overlayMaps = {
			Bodenfaktor: kFakt,
		};
	}

	lc = L.control.layers(baseLayers, overlayMaps, { position: 'bottomleft' }).addTo(map);
	map.on('baselayerchange', function (event) {
		//baselayer immer im Hintergrund
		event.layer.bringToBack();
	});
	map.on('overlayadd', function (event) {
		//Umschalten slope/lFakt/lsFakt als Radiobutton
		setTimeout(function () {
			if (event.layer == lFakt) {
				lFakt.bringToFront();
				if (map.hasLayer(lsFakt)) {
					map.removeLayer(lsFakt);
				}
				if (map.hasLayer(slope)) {
					map.removeLayer(slope);
				}
			}

			if (event.layer == lsFakt) {
				lsFakt.bringToFront();
				if (map.hasLayer(lFakt)) {
					map.removeLayer(lFakt);
				}
				if (map.hasLayer(slope)) {
					map.removeLayer(slope);
				}
			}

			if (event.layer == slope) {
				slope.bringToFront();
				if (map.hasLayer(lFakt)) {
					map.removeLayer(lFakt);
				}
				if (map.hasLayer(lsFakt)) {
					map.removeLayer(lsFakt);
				}
			}
			lc._update();
			lc.collapse();
		}, 100);
	});

	//Legenden erzeugen
	createLegend(lFakt, lsFakt, slope, kFakt, 'bayern');

	if (faktor == 'ls') {
		//Abfragetool für Länge und Höhe
		_createLSTool(map, _handleLSCalc);
	} else {
		//Abfragetool für K
		_createKTool(map, _handleKCalc);
	}
}

function initMapNRW(fullMap, faktor) {
	//Projektion für Daten festlegen
	proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
	proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs'); //UTM32
	//Events online/offline
	var dop80, flurstueck, lc;
	window.addEventListener('online', function () {
		updateOnlineMaps(true, dop80, flurstueck, lc);
		isOnline = true;
	});
	window.addEventListener('offline', function () {
		updateOnlineMaps(false, dop80, flurstueck, lc);
		isOnline = false;
	});

	map = L.map(faktor + 'map', {
		twoFingerZoom: true,
		editable: true,
	});

	//Aktionen nach laden der Karte
	map.on('load', function () {
		//Infos anzeigen
		Snackbar.show('Zur Eingabe des Linienverlaufs bitte Tool aktivieren');

		if (L.Browser.mobile && fullMap == true) {
			//Karte im Fullscreenmode zeigen wenn mobile
			displayMapFullScreen(true, faktor);
		}

		// if (_rPosition.length > 0) {
		// 	setTimeout(function(){
		// 		map.setView(_rPosition, 14);
		// 		//map.flyTo(_rPosition, 14);
		// 	}, 2000);
		// }

		map.invalidateSize(); // redraw
	});

	//Höhenlinien
	let lGroup = L.WMS.overlay('https://www.wms.nrw.de/geobasis/wms_nw_hl_hp_schwarz?', {
		layers: 'nw_hl_hp_hoehenlinien',
		format: 'image/png',
		transparent: true,
	});
	map.on('zoomend', function () {
		drawContour_NRW(lGroup, lc);
	});
	map.on('moveend', function () {
		drawContour_NRW(lGroup, lc);
	});

	//if (L.Browser.mobile) {
	var loc_icon =
		'<svg class="svg-inline--fa fa-location fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 168c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.94-40-40s17.94-40 40-40 40 17.94 40 40-17.94 40-40 40zm240-64h-49.66C435.49 145.19 366.81 76.51 280 65.66V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v49.66C145.19 76.51 76.51 145.19 65.66 232H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h49.66C76.51 366.81 145.19 435.49 232 446.34V496c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-49.66C366.81 435.49 435.49 366.8 446.34 280H496c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM256 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144z"/></svg>';

	L.easyButton(loc_icon, function () {
		map.locate({
			setView: true,
			watch: false,
			timeout: 50000,
			enableHighAccuracy: true,
		})
			.on('locationfound', function (e) {
				var marker = L.marker([e.latitude, e.longitude]).bindPopup('aktueller Standort)');
				map.addLayer(marker);
			})
			.on('locationerror', function (e) {
				Snackbar.show('Standort kann nicht ermittelt werden');
			});
	}).addTo(map);
	//}

	//#am
	//_rPosition = [51.64044, 7.55383]; // Initial NRW
	//Karte positionieren anhand Kachelinformation
	//map.setView(_rPosition, 13);

	//Gesamt boundary erzeugen
	//map.setView(_rPosition, 13);
	getEleTilesBound(map, 'nrw').then(function (bounds) {
		map.fitBounds(bounds);

		if (_rPosition.length > 0) {
			map.setView(_rPosition, 13);
	    }
	});

	var isOffline = !isOnline;
	var mnz = 19;
	if (isOffline) mnz = 16;

	const lFakt = new StorageTileLayer('./daten/daten_nrw/lfakt/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	const lsFakt = new StorageTileLayer('./daten/daten_nrw/lsfakt/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	const slope = new StorageTileLayer('./daten/daten_nrw/slope/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	const kFakt = new StorageTileLayer('./daten/daten_nrw/kfakt/{z}/{x}/{y}.png', {
		storage: isOffline,
		minZoom: 11,
		maxNativeZoom: 16,
		zIndex: 100,
		transparent: true,
	});

	const bSchaetzung = L.WMS.overlay('//www.wms.nrw.de/geobasis/wms_nw_alkis', {
		layers: 'adv_alkis_bodensch',
		format: 'image/png',
		maxZoom: 19,
		transparent: true,
		attribution: '&copy; Land NRW <a href="http://www.govdata.de/dl-de/by-2-0">www.govdata.de/dl-de/by-2-0</a>',
	});

	// folgendes nur wenn online
	// für farbdarstellung layers: 'nw_dtk_col',
	if (isOnline) {
		var osm = L.tileLayer
			.wms('https://www.wms.nrw.de/geobasis/wms_nw_dtk?', {
				layers: 'nw_dtk_sw',
				format: 'image/png',
				transparent: true,
				maxZoom: 19,
				minZoom: 6, //13,
				attribution: '&copy; Land NRW <a href="http://www.govdata.de/dl-de/by-2-0">www.govdata.de/dl-de/by-2-0</a>',
			})
			.addTo(map);

		// hier wird immer nur ein request gesendet (benötigt wms plugin)
		//Luftbilder
		dop80 = L.tileLayer.wms('//www.wms.nrw.de/geobasis/wms_nw_dop', {
			layers: 'nw_dop_rgb',
			format: 'image/png',
			transparent: true,
			minZoom: 8,
			attribution: '&copy; Land NRW <a href="http://www.govdata.de/dl-de/by-2-0">www.govdata.de/dl-de/by-2-0</a>',
		});

		//Flurstücke
		/*
		flurstueck = L.WMS.overlay('//www.wms.nrw.de/geobasis/wms_nw_alkis_gelb', {
			layers: 'adv_alkis_flurstuecke',
			format: 'image/png',
			transparent: true,
			attribution: '&copy; Land NRW <a href="http://www.govdata.de/dl-de/by-2-0">www.govdata.de/dl-de/by-2-0</a>',
		}).addTo(map);
		*/
		flurstueck = L.WMS.overlay('//www.wms.nrw.de/umwelt/lwk_eufoerderung', {
			layers: '13',
			format: 'image/png',
			transparent: true,
			attribution: '&copy; Land NRW <a href="http://www.govdata.de/dl-de/by-2-0">www.govdata.de/dl-de/by-2-0</a>',
		}).addTo(map);

		// zeitversetzt die flurstücke in den Vordergrund bringen
		// wird angesprungen bei zoomen und pannen
		var onMapZoomAndPan = function (e) {
			if (e.type == 'zoomend') {
				if (map.getZoom() > 10 && _baseLayerRadio == false) {
					_baseLayerRadio = true;
					console.log('mehr als 10');
					//Luftbilder anzeigen
					//dop80.addTo(map);
					//map.removeLayer(osm);
				} else if (map.getZoom() <= 10) {
					_baseLayerRadio = false;
					//OSM anzeigen
					//osm.addTo(map);
					//map.removeLayer(dop80);
				}
			}

			setTimeout(function () {
				flurstueck.bringToFront();
			}, 2500);
		};
		map.on('zoomend', onMapZoomAndPan);
		map.on('moveend', onMapZoomAndPan);
	}

	const attribution = document.querySelector('.leaflet-control-attribution');
	attribution.addEventListener('click', function (ev) {
		attribution.classList.toggle('attributionLong');
	});
	// Massstabsleiste
	L.control.scale({ imperial: false, position: 'bottomright' }).addTo(map);

	var baseLayer = {};
	if (isOnline) {
		/* Layer control anzeigen */
		//            "Openstreetmap": osm,
		var baseLayers = {
			'Amtliche Karte NRW': osm,
			Luftbilder: dop80,
		};
	}

	//zuschaltbare Layer (nur bei ls)
	var overlayMaps = {};
	if (faktor == 'ls') {
		overlayMaps = {
			Höhenlinien: lGroup,
			Hanglänge: lFakt,
			Hangneigung: slope,
			Topographiefaktor: lsFakt,
		};
	} else {
		overlayMaps = {
			Bodenfaktor: kFakt,
			'Analyse und Bodenschätzung': bSchaetzung,
		};
	}
	
	lc = L.control.layers(baseLayers, overlayMaps, { position: 'bottomleft' }).addTo(map);
	map.on('baselayerchange', function (event) {
		//baselayer immer im Hintergrund
		event.layer.bringToBack();
	});
	map.on('overlayadd', function (event) {
		//Umschalten slope/lFakt/lsFakt als Radiobutton
		setTimeout(function () {
			if (event.layer == lFakt) {
				lFakt.bringToFront();
				if (map.hasLayer(lsFakt)) {
					map.removeLayer(lsFakt);
				}
				if (map.hasLayer(slope)) {
					map.removeLayer(slope);
				}
			}

			if (event.layer == lsFakt) {
				lsFakt.bringToFront();
				if (map.hasLayer(lFakt)) {
					map.removeLayer(lFakt);
				}
				if (map.hasLayer(slope)) {
					map.removeLayer(slope);
				}
			}

			if (event.layer == slope) {
				slope.bringToFront();
				if (map.hasLayer(lFakt)) {
					map.removeLayer(lFakt);
				}
				if (map.hasLayer(lsFakt)) {
					map.removeLayer(lsFakt);
				}
			}
			lc._update();
			lc.collapse();
		}, 100);
	});

	//Legenden erzeugen
	createLegend(lFakt, lsFakt, slope, kFakt, 'nrw');

	if (faktor == 'ls') {
		//Abfragetool für Länge und Höhe
		_createLSTool(map, _handleLSCalc);
	} else {
		//Abfragetool für K
		_createKTool(map, _handleKCalc);
	}
}

function createLegend(lFakt, lsFakt, slope, kFakt, bl) {
	//Legenden
	L.control
		.htmllegend({
			position: 'bottomleft',
			collapseSimple: true,
			collapsedOnInit: false,
			disableVisibilityControls: true,
			detectStretched: true,
			legends: [
				{
					name: 'Legende',
					layer: lFakt,
					elements: [
						{
							label: ' ',
							html: '<div><img src="./daten/daten_' + bl + '/lfakt/lfakt_legend.png" /></div>',
						},
					],
				},
			],
		})
		.addTo(map);
		L.control
		.htmllegend({
			position: 'bottomleft',
			collapseSimple: true,
			collapsedOnInit: false,
			disableVisibilityControls: true,
			detectStretched: true,
			legends: [
				{
					name: 'Legende',
					layer: lsFakt,
					elements: [
						{
							label: ' ',
							html: '<div><img src="./daten/daten_' + bl + '/lsfakt/lsfakt_legend.png" /></div>',
						},
					],
				},
			],
		})
		.addTo(map);
	L.control
		.htmllegend({
			position: 'bottomleft',
			collapseSimple: true,
			collapsedOnInit: false,
			disableVisibilityControls: true,
			detectStretched: true,
			legends: [
				{
					name: 'Legende',
					layer: slope,
					elements: [
						{
							label: ' ',
							html: '<div><img src="./daten/daten_' + bl + '/slope/slope_legend.png" /></div>',
						},
					],
				},
			],
		})
		.addTo(map);
	L.control
		.htmllegend({
			position: 'bottomleft',
			collapseSimple: true,
			collapsedOnInit: false,
			disableVisibilityControls: true,
			detectStretched: true,
			legends: [
				{
					name: 'Legende',
					layer: kFakt,
					elements: [
						{
							label: ' ',
							html: '<div><img src="./daten/daten_' + bl + '/kfakt/kfakt_legend.png" /></div>',
						},
					],
				},
			],
		})
		.addTo(map);
}

function drawContour_NRW(lGroup, lc) {
	if (typeof map !== 'undefined') {
		if (lGroup) map.removeLayer(lGroup);
		//nur wenn Zoomstufe höher als 15
		if (map.getZoom() < 15) return;

		//Höhenlinien in Legende anzeigen
		lGroup.addTo(map);
	}
}

function drawContour(lGroup, lc) {
	if (typeof map !== 'undefined') {
		//bereits vorhandene Kacheln löschen, und Layer aus Legende entfernen
		lGroup.clearLayers();
		map.removeLayer(lGroup);
		if (lc) lc.removeLayer(lGroup);

		//nur wenn Zoomstufe höher als 15
		if (map.getZoom() < 15) return;

		//Höhenlinien in Legende anzeigen
		if (lc) lc.addOverlay(lGroup, 'Höhenlinien');
		lGroup.addTo(map);

		//Projektionen definieren
		proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
		var epsg4326 = '+proj=longlat +datum=WGS84 +no_defs'; //WGS84

		var tiles = [];
		if (_eleTiles.length == 0) {
			//derzeitigen View auslesen und Kacheln ermitteln
			var bounds = map.getBounds();
			var proj = proj4('EPSG:31464', [bounds.getSouthWest().lng, bounds.getSouthWest().lat]);
			var leftMin = Math.floor(proj[0] / 1000);
			var rightMin = Math.floor(proj[1] / 1000);
			proj = proj4('EPSG:31464', [bounds.getNorthEast().lng, bounds.getNorthEast().lat]);
			var leftMax = Math.floor(proj[0] / 1000);
			var rightMax = Math.floor(proj[1] / 1000);

			//Kacheln zusammenstellen
			for (var left = leftMin; left <= leftMax; left++) {
				for (var right = rightMin; right <= rightMax; right++) {
					tiles.push(left + '_' + right);
				}
			}
		} else {
			tiles = _eleTiles;
		}

		//Kacheln erzeugen und anzeigen
		var iBounds = [];
		var promises = [];
		tiles.forEach(function (tile) {
			var lr = tile.split('_');
			var coord_prj_von = proj4('EPSG:31464', epsg4326, [parseInt(lr[0]) * 1000, parseInt(lr[1]) * 1000]).reverse();
			var coord_prj_bis = proj4('EPSG:31464', epsg4326, [(parseInt(lr[0]) + 1) * 1000, (parseInt(lr[1]) + 1) * 1000]).reverse();
			var imageBounds = [coord_prj_von, coord_prj_bis];
			iBounds.push(imageBounds);

			if (('serviceWorker' in navigator && navigator.serviceWorker.controller) || isOnline) {
				promises.push(Promise.resolve('daten/daten_bayern/hoehen/dhk/' + tile + '.png'));
			} else {
				promises.push(DB.Util.getAdd('./daten/daten_bayern/hoehen/dhk/' + tile + '.png'));
			}
		});

		Promise.all(promises).then(function (values) {
			if (values) {
				//var hImages = [];
				var hImage;
				values.forEach(function (value) {
					var imageBounds = iBounds.shift();
					if (('serviceWorker' in navigator && navigator.serviceWorker.controller) || isOnline) {
						//hImages.push(L.imageOverlay(value, imageBounds));
						lGroup.addLayer(L.imageOverlay(value, imageBounds));
					} else {
						if (DB.Util.getBlobSaving() == 2) {
							//hImages.push(L.imageOverlay(decodeURIComponent(value), imageBounds));
							lGroup.addLayer(L.imageOverlay(decodeURIComponent(value), imageBounds));
						} else {
							//hImages.push(new BlobImageOverlay(value, imageBounds));
							lGroup.addLayer(new BlobImageOverlay(value, imageBounds));
						}
					}
				});

				//lGroup = L.layerGroup(hImages).addTo(map);
				//lGroup.id = 'hImages';
				lGroup.eachLayer(function (layer) {
					layer.bringToFront();
				});
			}
		});
	}
}

/*
function drawContour1() {
	if (typeof map !== 'undefined') {
		//bereits vorhandene Kacheln löschen
		var sLayer = null;
		map.eachLayer(function(layer) {
			if (layer.id == 'hImages') {
				sLayer = layer;
			}
		});
		if (sLayer) map.removeLayer(sLayer);

		//nur wenn Zoomstufe höher als 15
		if (map.getZoom() < 15) return;

		//Projektionen definieren
		proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
		var epsg4326 = '+proj=longlat +datum=WGS84 +no_defs'; //WGS84

		var tiles = [];
		if (_eleTiles.length == 0) {
			//derzeitigen View auslesen und Kacheln ermitteln
			var bounds = map.getBounds();
			var proj = proj4('EPSG:31464', [bounds.getSouthWest().lng, bounds.getSouthWest().lat]);
			var leftMin = Math.floor(proj[0] / 1000);
			var rightMin = Math.floor(proj[1] / 1000);
			proj = proj4('EPSG:31464', [bounds.getNorthEast().lng, bounds.getNorthEast().lat]);
			var leftMax = Math.floor(proj[0] / 1000);
			var rightMax = Math.floor(proj[1] / 1000);

			//Kacheln zusammenstellen
			for (var left = leftMin; left <= leftMax; left++) {
				for (var right = rightMin; right <= rightMax; right++) {
					tiles.push(left + '_' + right);
				}
			}
		} else {
			tiles = _eleTiles;
		}

		//Kacheln erzeugen und anzeigen
		var hImages = [];
		var promises = [];
		tiles.forEach(function(tile) {
			var lr = tile.split('_');
			var coord_prj_von = proj4('EPSG:31464', epsg4326, [parseInt(lr[0]) * 1000, parseInt(lr[1]) * 1000]).reverse();
			var coord_prj_bis = proj4('EPSG:31464', epsg4326, [(parseInt(lr[0]) + 1) * 1000, (parseInt(lr[1]) + 1) * 1000]).reverse();
			var imageBounds = [coord_prj_von, coord_prj_bis];
			var imageUrl;
			if (('serviceWorker' in navigator && navigator.serviceWorker.controller) || isOnline) {
				imageUrl = 'daten/hoehen/dhk/' + tile + '.png';
				hImages.push(L.imageOverlay(imageUrl, imageBounds));
			} else {
				DB.Util.getAdd('./daten/hoehen/dhk/' + tile + '.png').then(function(value) {
					if (value) {
						if (DB.Util.getBlobSaving() == 2) {
							value = decodeURIComponent(value);
						} else {
							value = URL.createObjectURL(value);
							URL.revokeObjectURL(value);
						}
						imageUrl = value;
						hImages.push(L.imageOverlay(imageUrl, imageBounds));
					}
				});
			}
		});
		var lGroup = L.layerGroup(hImages).addTo(map);
		lGroup.id = 'hImages';
	}
}
*/

function updateOnlineMaps(isOnline, dop80, flurstueck, lc) {
	if (typeof map !== 'undefined') {
		if (isOnline == true) {
			if (dop80) dop80.addTo(map);
			if (flurstueck) flurstueck.addTo(map);
			if (lc) lc.addTo(map);
		} else {
			if (dop80) dop80.remove();
			if (flurstueck) flurstueck.remove();
			if (lc) lc.remove();
		}
	}
}
//************************************************
// * K Faktor berechnen
//************************************************
function _handleKCalc(latlngs) {
	if (_elePoints) {
		//wenn k-Punkte schon existent, dann direkt berechnen
		_calcKFaktor(latlngs, _elePoints);
	} else {
		//noch keine Höhenpunkte vorhanden, Dann
		createEleLayer().then(function () {
			_calcKFaktor(latlngs, _elePoints);
		});
	}
}
function _calcKFaktor(latlngs, data) {
	//wert zurücksetzen
	document.getElementById('K-map-input').innerHTML = '0';

	//K berechnen
	const polyArray = [];
	_kpolygon = []; //Polygon für LS-Darstellung zwischenspeichern
	latlngs.forEach(function (latlng) {
		lat = latlng.lat;
		lng = latlng.lng;
		polyArray.push([lng, lat]);
		_kpolygon.push([lat,lng]);
	});
	polyArray.push([latlngs[0].lng, latlngs[0].lat]);
	_kpolygon.push([latlngs[0].lat, latlngs[0].lng]);

	const polygon = turf.helpers.polygon([polyArray]);
	var ptsWithin = turf.pointsWithinPolygon(data, polygon);
	console.log(ptsWithin);
	let kSum = 0;
	ptsWithin.features.forEach(function (feature) {
		const k = parseFloat(feature.properties.wert);
		kSum += k;
	});

	//Mittelwert berechnen und Wert präsentieren
	const kMean = kSum / ptsWithin.features.length;
	console.log(kMean);
	document.getElementById('K-map-input').innerHTML = kMean.toFixed(2);
	setKFaktor('K-map');
}

function _createKTool(map, callback) {
	var trash_icon =
		'<svg class="svg-inline--fa fa-times fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M192 188v216c0 6.627-5.373 12-12 12h-24c-6.627 0-12-5.373-12-12V188c0-6.627 5.373-12 12-12h24c6.627 0 12 5.373 12 12zm100-12h-24c-6.627 0-12 5.373-12 12v216c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V188c0-6.627-5.373-12-12-12zm132-96c13.255 0 24 10.745 24 24v12c0 6.627-5.373 12-12 12h-20v336c0 26.51-21.49 48-48 48H80c-26.51 0-48-21.49-48-48V128H12c-6.627 0-12-5.373-12-12v-12c0-13.255 10.745-24 24-24h74.411l34.018-56.696A48 48 0 0 1 173.589 0h100.823a48 48 0 0 1 41.16 23.304L349.589 80H424zm-269.611 0h139.223L276.16 50.913A6 6 0 0 0 271.015 48h-94.028a6 6 0 0 0-5.145 2.913L154.389 80zM368 128H80v330a6 6 0 0 0 6 6h276a6 6 0 0 0 6-6V128z"/></svg>';
	var polygon_icon =
		'<svg viewBox="-2 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M18,16.5C17.705,16.5 17.425,16.556 17.156,16.636L15.636,14.103C16.169,13.561 16.5,12.82 16.5,12C16.5,11.18 16.169,10.439 15.637,9.897L17.156,7.364C17.426,7.444 17.705,7.5 18.001,7.5C19.658,7.5 21.001,6.157 21.001,4.5C21.001,2.843 19.658,1.5 18.001,1.5C16.604,1.5 15.441,2.459 15.106,3.75L5.894,3.75C5.559,2.459 4.396,1.5 3,1.5C1.343,1.5 0,2.843 0,4.5C0,5.896 0.958,7.059 2.25,7.394L2.25,16.606C0.958,16.941 0,18.104 0,19.5C0,21.157 1.343,22.5 3,22.5C4.396,22.5 5.559,21.542 5.894,20.25L15.106,20.25C15.441,21.542 16.604,22.5 18.001,22.5C19.658,22.5 21.001,21.157 21.001,19.5C21,17.843 19.657,16.5 18,16.5ZM3.75,16.606L3.75,7.394C4.8,7.122 5.622,6.301 5.894,5.25L15.106,5.25C15.24,5.768 15.5,6.234 15.864,6.603L14.344,9.135C14.075,9.056 13.795,9 13.5,9C11.843,9 10.5,10.343 10.5,12C10.5,13.657 11.843,15 13.5,15C13.795,15 14.075,14.944 14.344,14.865L15.864,17.397C15.5,17.766 15.24,18.233 15.106,18.75L5.894,18.75C5.622,17.701 4.799,16.878 3.75,16.606ZM13.5,13.5C12.673,13.5 12,12.827 12,12C12,11.173 12.673,10.5 13.5,10.5C14.327,10.5 15,11.173 15,12C15,12.827 14.327,13.5 13.5,13.5ZM18,3C18.827,3 19.5,3.673 19.5,4.5C19.5,5.327 18.827,6 18,6C17.173,6 16.5,5.327 16.5,4.5C16.5,3.673 17.173,3 18,3ZM1.5,4.5C1.5,3.673 2.173,3 3,3C3.827,3 4.5,3.673 4.5,4.5C4.5,5.327 3.827,6 3,6C2.173,6 1.5,5.327 1.5,4.5ZM3,21C2.173,21 1.5,20.327 1.5,19.5C1.5,18.673 2.173,18 3,18C3.827,18 4.5,18.673 4.5,19.5C4.5,20.327 3.827,21 3,21ZM18,21C17.173,21 16.5,20.327 16.5,19.5C16.5,18.673 17.173,18 18,18C18.827,18 19.5,18.673 19.5,19.5C19.5,20.327 18.827,21 18,21Z"/></svg>';
	L.NewPolygonControl = L.Control.extend({
		options: {
			position: 'topleft',
		},
		onAdd: function (map) {
			var container = L.DomUtil.create('div', 'leaflet-control leaflet-bar'),
				link = L.DomUtil.create('a', '', container);

			link.href = '#';
			link.title = 'Fläche definieren';
			link.innerHTML = polygon_icon;
			L.DomEvent.on(link, 'click', L.DomEvent.stop).on(link, 'click', function () {
				Snackbar.show('Doppelklick auf den Punkt beendet Eingabe.');
				map.editTools.startPolygon();
			});

			return container;
		},
	});
	L.DeleteControl = L.Control.extend({
		options: {
			position: 'topleft',
		},
		onAdd: function (map) {
			var container = L.DomUtil.create('div', 'leaflet-control leaflet-bar'),
				link = L.DomUtil.create('a', '', container);

			link.href = '#';
			link.title = 'Fläche löschen';
			link.innerHTML = trash_icon;
			L.DomEvent.on(link, 'click', L.DomEvent.stop).on(link, 'click', function () {
				map.editTools.featuresLayer.clearLayers();
			});

			return container;
		},
	});
	map.addControl(new L.NewPolygonControl());
	map.addControl(new L.DeleteControl());

	//alte Polygone löschen
	map.on('editable:drawing:start', function (e) {
		e.editTools.featuresLayer.clearLayers();
	});

	//Koordinaten ermitteln
	let latlngs = [];
	map.on('editable:drawing:commit', function (e) {
		const layer = e.layer;
		latlngs = layer.getLatLngs()[0];

		//Polgon für LS-Darstellung zwischenspeichern
		//_kpolygon = layer.toGeoJSON();

		//jetzt analysieren
		Snackbar.show('K-Wert wird nun ermittelt', ' ');

		var exe = function (s) {
			if (_eleTiles.length == 0) {
				console.log('keine Tiles');
			} else {
				_elePoints = null;
				console.log(_eleTiles);
				callback(s);
			}
		};

		//Wenn Online, dann Höhenkacheln direkt berechnen
		if (isOnline) {
			//benötigte Höhenkacheln ermitteln
			let leftMin = 10000;
			let leftMax = 0;
			let rightMin = 10000;
			let rightMax = 0;
			for (var i = 0; i < latlngs.length; i++) {
				var lat = latlngs[i].lat;
				var lng = latlngs[i].lng;

				//Projektion für Daten festlegen
				proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
				var proj = proj4('EPSG:31464', [lng, lat]);

				var left = Math.floor(proj[0] / 1000);
				var right = Math.floor(proj[1] / 1000);

				leftMin = Math.min(leftMin, left);
				leftMax = Math.max(leftMax, left);
				rightMin = Math.min(rightMin, right);
				rightMax = Math.max(rightMax, right);
			}
			if ((leftMax - leftMin) * (rightMax - rightMin) > 20) {
				Snackbar.show('Eingabe erzeugt zuviele Daten.', 'OK');
			} else {
				const proms = [];
				for (iLeft = leftMin; iLeft <= leftMax; iLeft++) {
					for (iRight = rightMin; iRight <= rightMax; iRight++) {
						console.log(iLeft + '_' + iRight);
						//Promise speichern
						proms.push(_eleTileExists(iLeft + '_' + iRight));
					}
				}

				//Wenn überprüfung der K-kacheln beendet, dann K berechnen
				var latlngsTmp = latlngs; //latlngs sichern, da bevor promise abgearbeitet segments schon wieder durch "beforestart" zurückgesetzt sein kann
				Promise.all(proms)
					.then(function () {
						exe(latlngsTmp);
					})
					.catch(function (e) {
						//snackbar wieder löschen (können mehrere sein)
						var sBars = document.querySelectorAll('.paper-snackbar');
						for (var i = 0; i < sBars.length; ++i) {
							sBars[i].style.opacity = 0;
						}
						Snackbar.show('Für einige Punkte können keine Höhen ermittelt werden. Bitte wiederholen Sie ihre Eingabe', 'Weiter');
					});
			}
			//polygon löschen
			//layer.editor.deleteShapeAt(layer.getCenter());
		} else {
			exe(latlngs);
		}
	});

	var deleteShape = function (e) {
		if ((e.originalEvent.ctrlKey || e.originalEvent.metaKey) && this.editEnabled()) this.editor.deleteShapeAt(e.latlng);
	};

	map.on('layeradd', function (e) {
		if (e.layer instanceof L.Path) e.layer.on('click', L.DomEvent.stop).on('click', deleteShape, e.layer);
		if (e.layer instanceof L.Path) e.layer.on('dblclick', L.DomEvent.stop).on('dblclick', e.layer.toggleEdit);
	});
}

//************************************************
// * LS Faktor berechnen
//************************************************

function _handleLSCalc(segments) {
	if (_elePoints) {
		//wenn Höhenpunkte schon existent, dann direkt berechnen
		_calcLSFaktor(segments, _elePoints);
	} else {
		//noch keine Höhenpunkte vorhanden, Dann
		createEleLayer().then(function () {
			_calcLSFaktor(segments, _elePoints);
		});
	}
}

function _calcLSFaktor(segments, data) {
	//variablen definieren
	var cellSize = 5;
	if (localStorage.getItem('bl') == 'Nordrhein-Westfalen') {
		cellSize = 10;
	}
	var hoeheFirst = 0;
	var hoeheLast = 0;
	var lat = 0;
	var lng = 0;
	var point = null;
	var nearestVon = null;
	var distanceVon = 0;
	var nearestBis = null;
	var distanceBis = 0;
	var segSlopes = [];

	segments.forEach(function (segment) {
		if (segment.length == 0) {
			//wenn erstes mal von-werte lesen
			lat = segment.latlngs.lat;
			lng = segment.latlngs.lng;
			point = turf.helpers.point([lng, lat]);
			nearestVon = turf.nearestPoint(point, data);
			distanceVon = turf.distance(nearestVon, point) * 1000;
			hoeheFirst = parseFloat(nearestVon.properties.wert);

			return;
		}

		//bis-werte lesen
		lat = segment.latlngs.lat;
		lng = segment.latlngs.lng;
		point = turf.helpers.point([lng, lat]);
		nearestBis = turf.nearestPoint(point, data);
		distanceBis = turf.distance(nearestBis, point) * 1000;
		hoeheLast = parseFloat(nearestBis.properties.wert);

		//wenn distanz kleiner zellenweite (diagonal) dann ist ein Höhenpunkt in der nähe
		if (Math.max(distanceVon, distanceBis) <= cellSize * Math.SQRT2) {
			var hoeheVon = parseFloat(nearestVon.properties.wert);
			var hoeheBis = parseFloat(nearestBis.properties.wert);

			//berechnung Neigung
			var slope = (Math.abs(hoeheBis - hoeheVon) / segment.length) * 100.0;
			segSlopes.push({ length: segment.length, slope: slope });
		}

		//jetzt sind neue von Werte alte bis werte
		distanceVon = distanceBis;
		nearestVon = nearestBis;
	});

	//regSlopes immer von oben nach unten => umdrehen falls hoeheFirst < hoeheLast
	if (hoeheFirst < hoeheLast) {
		segSlopes.reverse();
	}
	console.log(segSlopes);

	loadLSDetailliertSegment(segSlopes);
}

function _createLSTool(map, callback) {
	//K-Abgrenzung anzeigen
	if (_kpolygon) {
		L.polyline(_kpolygon, {color: '#3388ff', weight: 3, stroke: true, opacity: 1}).addTo(map);
		_rPosition = _kpolygon[0];
	}

	var segments = [];
	var trash_icon =
		'<svg class="svg-inline--fa fa-times fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M192 188v216c0 6.627-5.373 12-12 12h-24c-6.627 0-12-5.373-12-12V188c0-6.627 5.373-12 12-12h24c6.627 0 12 5.373 12 12zm100-12h-24c-6.627 0-12 5.373-12 12v216c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V188c0-6.627-5.373-12-12-12zm132-96c13.255 0 24 10.745 24 24v12c0 6.627-5.373 12-12 12h-20v336c0 26.51-21.49 48-48 48H80c-26.51 0-48-21.49-48-48V128H12c-6.627 0-12-5.373-12-12v-12c0-13.255 10.745-24 24-24h74.411l34.018-56.696A48 48 0 0 1 173.589 0h100.823a48 48 0 0 1 41.16 23.304L349.589 80H424zm-269.611 0h139.223L276.16 50.913A6 6 0 0 0 271.015 48h-94.028a6 6 0 0 0-5.145 2.913L154.389 80zM368 128H80v330a6 6 0 0 0 6 6h276a6 6 0 0 0 6-6V128z"/></svg>';
	var line_icon =
		'<svg class="svg-inline--fa fa-times fa-w-18" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M23 8c0 1.1-.9 2-2 2-.18 0-.35-.02-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07l-4.55 4.56c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C8.02 9.36 8 9.18 8 9c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56C19.02 8.35 19 8.18 19 8c0-1.1.9-2 2-2s2 .9 2 2z"/></svg>';

	var control = L.control
		.polylineMeasure({
			position: 'topleft',
			backgroundColor: '#fbc02d',
			clearMeasurementsOnStop: false,
			showClearControl: true,
			measureControlLabel: line_icon,
			measureControlTitleOn: 'Definiere Hanglänge',
			measureControlTitleOff: 'Beende Hanglängendefinition',
			clearControlLabel: trash_icon,
			clearControlTitle: 'Linie löschen',
		})
		.addTo(map);

	//control.on("beforestart", function() {
	//control.on("enable", function(ev) {
	map.on('polylinemeasure:toggle', function (ev) {
		if (ev.sttus == true) {
			console.log('enable ');

			control._clearAllMeasurements();
			//Linienverlauf löschen
			segments = [];
			//eleTiles zurücksetzen für neue Auswahl
			if (isOnline) _eleTiles = [];

			//control.reset();
			if (L.Browser.mobile) {
				Snackbar.show('Click definiert Linie. Zum Berechnen Tool wieder ausschalten.');
			} else {
				Snackbar.show('Click definiert Linie, erneuter Click auf den Punkt beendet Eingabe.');
			}
		}
	});

	//control.on("path", function(data) {
	map.on('polylinemeasure:add', function (data) {
		var tmp = {
			latlngs: data.latlng,
			length: control._currentLine.lastSegmentDistance,
		};
		segments.push(tmp);
	});
	//control.on("stop", function(data) {
	map.on('polylinemeasure:finish', function (data) {
		console.log('stop');

		//wenn keine Segmente vorhanden, dann ist hier schluss
		if (segments.length == 0) return;

		//ausschalten
		window.setTimeout(function () {
			var toggleBut = document.querySelector('.polyline-measure-controlOnBgColor');
			if (toggleBut) toggleBut.click();
		}, 200);

		//jetzt analysieren
		Snackbar.show('Werte werden nun berechnet', ' ');

		var exe = function (s) {
			if (_eleTiles.length == 0) {
				console.log('keine Tiles');
			} else {
				_elePoints = null;
				console.log(_eleTiles);
				callback(s);
			}
		};

		//Wenn Online, dann Höhenkacheln direkt berechnen
		if (isOnline) {
			//benötigte Höhenkacheln ermitteln
			var proms = [];
			for (var i = 0; i < segments.length; i++) {
				var latlngs = segments[i].latlngs;
				var lat = latlngs.lat;
				var lng = latlngs.lng;

				//Projektion für Daten festlegen
				proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
				var proj = proj4('EPSG:31464', [lng, lat]);

				var left = Math.floor(proj[0] / 1000);
				var right = Math.floor(proj[1] / 1000);

				//Promise speichern
				proms.push(_eleTileExists(left + '_' + right));
			}

			//Wenn überprüfung der Höhenkacheln beendet, dann LS berechnen
			var segmentsTmp = segments; //segments sichern, da bevor promise abgearbeitet segments schon wieder durch "beforestart" zurückgesetzt sein kann
			Promise.all(proms)
				.then(function () {
					exe(segmentsTmp);
				})
				.catch(function (e) {
					//snackbar wieder löschen (können mehrere sein)
					var sBars = document.querySelectorAll('.paper-snackbar');
					for (var i = 0; i < sBars.length; ++i) {
						sBars[i].style.opacity = 0;
					}
					Snackbar.show('Für einige Punkte können keine Höhen ermittelt werden. Bitte wiederholen Sie ihre Eingabe', 'Weiter');
				});
		} else {
			exe(segments);
		}
	});
	//control.on("clear", function(data) {
	map.on('polylinemeasure:clear', function (data) {
		console.log('clear');

		//segments zurücksetzen
		segments = [];
		clearLSDetailliertSegment();
	});
	//control.on("change", function(data) {
	map.on('polylinemeasure:remove', function (data) {
		if (segments.length == 0) return;
		var lastSegment = segments[segments.length - 1];

		//letzter Punkt wurde entfernt
		if (lastSegment.latlngs.lat == data.latlng.lat && lastSegment.latlngs.lng == data.latlng.lng) {
			segments.pop();
			return;
		}

		//erster Punkt wurde entfernt
		if (segments[0].latlngs.lat == data.latlng.lat && segments[0].latlngs.lng == data.latlng.lng) {
			segments[1].length = 0;
			segments.shift();
			return;
		}

		//Punkt in der Mitte wurde entfernt
		var index = -1;
		var length = 0;
		segments.forEach(function (segment, i) {
			if (segment.latlngs.lat == data.latlng.lat && segment.latlngs.lng == data.latlng.lng) {
				index = i;
				length = segment.length;
			}
		});
		segments[index + 1].length += length;
		segments.splice(index, 1);

		/*
        var pv, pn = null;
        if (data.marker > 0) {
            //neue Koordinate dem Linienstück vor Punkt zuweisen
            segments[data.marker - 1].latlngs[1] = data.coord;
            //neue Distanz berechnen
            pv = turf.helpers.point([segments[data.marker - 1].latlngs[0].lng, segments[data.marker - 1].latlngs[0].lat]);
            pn = turf.helpers.point([segments[data.marker - 1].latlngs[1].lng, segments[data.marker - 1].latlngs[1].lat]);
            segments[data.marker - 1].length = turf.distance(pv, pn) * 1000;
        }

        if (data.marker < segments.length) {
            //neue Koordinate dem Linienstück nach Punkt zuweisen
            segments[data.marker].latlngs[0] = data.coord;
            //neue Distanz berechnen
            pv = turf.helpers.point([segments[data.marker].latlngs[0].lng, segments[data.marker].latlngs[0].lat]);
            pn = turf.helpers.point([segments[data.marker].latlngs[1].lng, segments[data.marker].latlngs[1].lat]);
            segments[data.marker].length = turf.distance(pv, pn) * 1000;
        }

        callback(segments);
		*/
	});
}

//************************************************
// * Allgemein
//************************************************
function _eleTileExists(tile) {
	//uerpruefen ob Hohenpunkte als Datei existiert
	return new Promise(function (resolve, reject) {
		if (isOnline) {
			try {
				var url = getEleTileUrl(tile, true);
				var client = new XMLHttpRequest();
				client.open('HEAD', url);
				client.onload = function () {
					if (this.status === 200) {
						if (_eleTiles.indexOf(tile) === -1) {
							_eleTiles.push(tile);
						}
						resolve();
					} else if (this.status >= 400) {
						reject();
					}
				};
				client.send();
			} catch (e) {
				reject();
			}
		} else {
			if (_eleTiles[tile]) resolve();
			else reject();
		}
	});
}

function readAsyncFile(file, type, callback) {
	var rawFile = new XMLHttpRequest();
	rawFile.open('GET', file, true);
	if (type == 'json') rawFile.overrideMimeType('application/json');
	rawFile.onreadystatechange = function () {
		if (rawFile.readyState === 4 && rawFile.status == '200') {
			if (type == 'json') {
				callback(rawFile.responseText);
			} else {
				callback(rawFile.responseText.split('\n'));
			}
		}
	};
	rawFile.send(null);
}

function getEleTileUrl(tile, forSW) {
	let bl = 'bayern';
	if (localStorage.getItem('bl') == 'Nordrhein-Westfalen') {
		bl = 'nrw';
	}
	if (!map || map._container.id == 'lsmap') {
		if (forSW == true) return './daten/daten_' + bl + '/hoehen/dgm/' + tile + '.txt.gz';
		else return '../../daten/daten_' + bl + '/hoehen/dgm/' + tile + '.txt.gz';
	} else {
		if (forSW == true) return './daten/daten_' + bl + '/k/' + tile + '.txt.gz';
		else return '../../daten/daten_' + bl + '/k/' + tile + '.txt.gz';
	}
}

function getContourTileUrl(tile, forSW) {
	// Contour wird nur für Bayern benötigt
	let bl = 'bayern';
	if (forSW == true) return './daten/daten_' + bl + '/hoehen/dhk/' + tile + '.png';
	else return '../../daten/daten_' + bl + '/hoehen/dhk/' + tile + '.png';
}

function getEleTilesBound(mapTmp, bl) {
	return new Promise(function (resolve, reject) {
		if (_eleTiles.length > 0) {
			//Array mit Tile Urls erzeuge und deren Boundaries anzeigen
			var xVon = 10000000;
			var xBis = 0;
			var yVon = 10000000;
			var yBis = 0;
			var bounds = null;
			var eleFiles = [];

			proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
			_eleTiles.forEach(function (tile) {
				eleFiles.push(getEleTileUrl(tile));
				//elefiles.push("../../daten/hoehen/dgm/" + tile + ".txt.gz");

				var coord = tile.split('_');
				var x1 = parseInt(coord[0]) * 1000;
				var y1 = parseInt(coord[1]) * 1000;
				var x2 = parseInt(coord[0]) * 1000 + 1000;
				var y2 = parseInt(coord[1]) * 1000 + 1000;
				var epsg4326 = '+proj=longlat +datum=WGS84 +no_defs'; //WGS84
				var coord_prj_von = proj4('EPSG:31464', epsg4326, [x1, y1]);
				var coord_prj_bis = proj4('EPSG:31464', epsg4326, [x2, y2]);

				xVon = Math.min(xVon, coord_prj_von[0]);
				yVon = Math.min(yVon, coord_prj_von[1]);
				xBis = Math.max(xBis, coord_prj_bis[0]);
				yBis = Math.max(yBis, coord_prj_bis[1]);

				// create an orange rectangle
				/*
				if (mapTmp)
					L.rectangle([coord_prj_von.reverse(), coord_prj_bis.reverse()], {
						interactive: false,
						color: '#ff7800',
						weight: 0,
					}).addTo(mapTmp);
				*/
			});

			/*
			//Mittelpunkt setzen
			const yMit = yVon + ((yBis - yVon) / 2);
			const xMit = xVon + ((xBis - xVon) / 2);
			map.setView([yMit, xMit], 13);
			*/

			//Gesamt boundary erzeugen
			resolve([
				[yVon, xVon],
				[yBis, xBis],
			]);
		} else {
			readAsyncFile('daten/daten_' + bl + '/grenze_geojson.json', 'json', function (jsonText) {
				var data = JSON.parse(jsonText);
				var bayern = L.geoJson(data, { interactive: false });
				//var bayern = L.geoJson(data, { interactive: false }).addTo(map);
				resolve(bayern.getBounds());
			});
		}
	});
}

function createGeoJson(dataUr) {
	proj4.defs('EPSG:31464', '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +units=m +no_defs'); //DHDN_3_Degree_Gauss_Zone_4
	var epsg4326 = '+proj=longlat +datum=WGS84 +no_defs'; //WGS84

	//geojson erstellen
	var data = turf.helpers.featureCollection(
		dataUr.map(function (tElement) {
			var element = tElement.split(' ');
			//projiziere von DHDN_3_Degree_Gauss_Zone_4 nach WGS84
			var proj = proj4('EPSG:31464', epsg4326, [element[0], element[1]]);

			return turf.helpers.point([proj[0], proj[1]], { hoehe: element[2] });
		})
	);

	return data;
}

function createEleLayer(eleData) {
	//keine vorgeladenen Daten
	if (!eleData) {
		return new Promise(function (resolve, reject) {
			//falls noch nicht existent hoehenpunkte vorladen entweder über serviceworker oder von indexeddb
			//if (!_elePoints) {
			if (('serviceWorker' in navigator && navigator.serviceWorker.controller) || isOnline) {
				_handleEleSWOffline().then(function () {
					resolve();
				});
			} else {
				_handleEleBase64Offline().then(function () {
					resolve();
				});
			}
			//}
		});
	} else {
		//Hoehendaten stammen vom Einlesen der Daten in die indexedb
		var dataUr = '';
		var proms = [];
		var dataAdd = function (value) {
			return new Promise(function (resolve, reject) {
				if (typeof value == 'string') {
					//Base64 in ArrayBuffer umwandeln, unzippen und in dataUr summieren
					value = DB.Util.base64ToArrayBuffer(decodeURIComponent(value).split(',')[1]);
					dataUr += DB.Util.unzip(value);
					resolve();
				} else {
					//Blob umwandeln in ArrayBuffer, unzippen und in dataUr summieren
					var reader = new FileReader();
					reader.onload = function () {
						dataUr += DB.Util.unzip(reader.result);
						resolve();
					};
					reader.readAsArrayBuffer(value);
				}
			});
		};

		//alle Promises sammeln und wenn alle Promises erledigt, dann GeoJason erzeugen
		eleData.forEach(function (value) {
			proms.push(dataAdd(value));
		});
		Promise.all(proms).then(function () {
			var worker = new Worker('syslib/js/loadworker.min.js');
			worker.addEventListener(
				'message',
				function (e) {
					worker.terminate();
					if (e.data == '') return;
					_elePoints = e.data;
				},
				false
			);
			//Daten an den Worker senden
			worker.postMessage([dataUr, 'geojson']);
			//_elePoints = createGeoJson(dataUr.split("\n"));
		});
	}
}

function _handleEleBase64Offline() {
	return new Promise(function (resolve, reject) {
		//array mit URLs erzeugen
		var fileNames = [];
		_eleTiles.forEach(function (tile) {
			fileNames.push(getEleTileUrl(tile, true));
		});

		//Hoehendaten aus indexeddb einlesen unzippen und als eine GeoJson zurückgeben (WebWorker)
		var i = 0;
		var dataUr = '';
		fileNames.forEach(function (file) {
			DB.Util.getAdd(file).then(function (value) {
				i++;
				value = DB.Util.base64ToArrayBuffer(decodeURIComponent(value).split(',')[1]);
				dataUr += DB.Util.unzip(value);
				if (i == fileNames.length) {
					_elePoints = createGeoJson(dataUr.split('\n'));
					resolve();
				}
			});
		});
	});
}

function _handleEleSWOffline() {
	return new Promise(function (resolve, reject) {
		//array mit URLs erzeugen
		var fileNames = [];
		_eleTiles.forEach(function (tile) {
			fileNames.push(getEleTileUrl(tile));
		});

		//Hoehendaten einlesen (daten stammen aus gecachten SW-Daten) unzippen und als eine GeoJson zurückgeben (WebWorker)
		var worker = new Worker('syslib/js/loadworker.min.js');
		worker.addEventListener(
			'message',
			function (e) {
				worker.terminate();
				if (e.data == '') return;
				if (e.data.type && e.data.type == 'FeatureCollection') {
					//_elePoints = JSON.parse(e.data);
					_elePoints = e.data;
					resolve();
				} else {
					//Workaround für Edge. Zippen funktioniert dort nicht im WebWorker
					var dataUr = '';
					e.data.forEach(function (ab) {
						dataUr += DB.Util.unzip(ab);
					});
					_elePoints = createGeoJson(dataUr.split('\n'));
					resolve();
				}
			},
			false
		);
		//Daten an den Worker senden
		var type = 'zip';
		if (L.Browser.edge) type = 'zipedge';
		worker.postMessage([fileNames, type]);
	});
}
